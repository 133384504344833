<template>
  <v-container class="pa-0" fluid>
    <v-container fluid>
      <v-progress-linear
        v-if="isLoadingShipmentSummary"
        indeterminate
        color="cyan"
      ></v-progress-linear>
      <v-row v-else justify="space-around">
        <v-col cols="auto">
          <summary-item
            status="new"
            :count="shipmentSummary.created"
            :loading="isLoadingShipmentSummary"
            @changePath="changePath('process')"
          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="pickup"
            :count="shipmentSummary.pickup"
            :loading="isLoadingShipmentSummary"
            @changePath="changePath('process')"
          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="delivery"
            :count="shipmentSummary.delivery"
            :loading="isLoadingShipmentSummary"
            @changePath="changePath('process')"
          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="needConfirm"
            :count="shipmentSummary.confirm"
            :loading="isLoadingShipmentSummary"
            @changePath="changePath('needconfirmation')"
          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="complete"
            :count="shipmentSummary.completed && (shipmentSummary.completed > 999 ? '999+' : shipmentSummary.completed)"
            :loading="isLoadingShipmentSummary"
            @changePath="changePath('completed')"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-tabs
            v-model="activeTab"
            background-color="transparent"
            active-class="font-weight-bold"
          >
            <v-tab @click="changePath('needconfirmation')" class="body-2 text-capitalize">
              {{$_strings.statusOrder.NEED_CONFIRMATION}}
            </v-tab>
            <v-tab @click="changePath('process')" class="body-2 text-capitalize">
              {{$_strings.statusOrder.IN_PROCESS}}
            </v-tab>
            <v-tab @click="changePath('completed')" class="body-2 text-capitalize">
              {{$_strings.statusOrder.COMPLETED}}
            </v-tab>
          </v-tabs>
          <div style="margin-top:-2px">
            <v-divider v-for="divider in 3" :key="divider"></v-divider>
          </div>
        </v-col>
      </v-row>
      <v-card
        elevation="5"
        tile
      >
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <NeedConfirm />
          </v-tab-item>
          <v-tab-item>
            <Process />
          </v-tab-item>
          <v-tab-item>
            <Completed />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import axios from 'axios';
import SummaryItem from '@/components/StatusOrder/SummaryItem.vue';
import NeedConfirm from './List/NeedConfirm.vue';
import Process from './List/Process.vue';
import Completed from './List/Completed.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'CompanyRegistrationPage',
  components: {
    SummaryItem,
    NeedConfirm,
    Process,
    Completed,
  },
  created() {
    source = CancelToken.source();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    const { tab } = this.$route.params;
    return {
      isLoadingShipmentSummary: false,
      activeTab: ['needconfirmation', 'process', 'completed'].indexOf(tab),
      shipmentSummary: {
        completed: '',
        confirm: '',
        created: '',
        delivery: '',
        pickup: '',
      },
      path: '',
    };
  },
  methods: {
    getShipmentSummary() {
      this.isLoadingShipmentSummary = true;
      this.$_services.order.getShipmentSummary(source)
        .then((res) => {
          this.shipmentSummary = res.data;
        }).finally(() => {
          this.isLoadingShipmentSummary = false;
        });
    },
    changePath(path) {
      if (this.path.localeCompare(path) !== 0) {
        this.path = path;
        this.activeTab = ['needconfirmation', 'process', 'completed'].indexOf(path);
        this.$router.replace(`/main/status-pesanan/list/${path}`);
      }
    },
    setActiveTab(tab) {
      switch (tab) {
        case 'needconfirmation':
          this.path = 'needconfirmation';
          this.activeTab = 0;
          break;
        case 'process':
          this.path = 'process';
          this.activeTab = 1;
          break;
        default:
          this.path = 'completed';
          this.activeTab = 2;
          break;
      }
    },
  },
  mounted() {
    const { tab } = this.$route.params;
    if (tab) {
      this.setActiveTab(tab);
    }
    this.getShipmentSummary();
  },
};
</script>

<style>
</style>
