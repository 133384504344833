<template>
  <v-container fluid>
    <FilterOrder
      @setFilter="setFilter"
    />
    <v-sheet
      min-height="200"
      class="w-100 d-flex align-center justify-center"
      v-if="orderList.length === 0"
    >
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="primary"
        class="w-100 mt-10 mb-10"
      ></v-progress-circular>
      <h2 v-if="!isLoading">{{$_strings.common.NO_DATA}}</h2>
    </v-sheet>
    <section v-else class="my-4">
      <v-card
        :to="`/main/status-pesanan/detail/${item.id}?status=NEED_CONFIRMATION&title=${item.title}&latestStatus=${item.latestStatus}&serviceType=${item.serviceType}&pickupDateFrom=${dateFilterFormat(filter.pickupDateFrom)}&pickupDateTo=${dateFilterFormat(filter.pickupDateTo)}`"
        hover
        class="mb-5"
        @click="viewDetail(item.id, item.title, item.latestStatus, item.serviceType)"
        v-for="(item) in orderList"
        v-bind:key="item.id"
      >
        <v-card-title>
          <v-row justify="space-between">
            <v-col>
              <v-icon class="body-2 ml-2 mr-3">mdi-archive</v-icon>
              <span class="body-2">{{item.title}} </span>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="orange"
                dark
                small
              >
                {{item.latestStatus}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="d-flex align-center" cols="auto">
              <v-icon color="light-blue lighten-2">
                mdi-map-marker
              </v-icon>
              <p class="mb-0 black--text pl-3">
                {{item.originLocationName}} <br />
                <span class="grey--text text--darken-1">{{item.originCityName}} </span>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-row>
                <v-col class="pt-0" cols="auto">
                  <v-icon color="yellow accent-4">
                    mdi-map-marker
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-col class="d-flex align-center pa-0" cols="10">
                  <p class="black--text">
                    {{item.destinationLocationName}} <br />
                    <span class="grey--text text--darken-1">{{item.destinationCityName}} </span>
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex pb-0 pt-0 justify-end">
              <p class="grey--text">{{dateFormat(item.pickupDate)}}</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-intersect="infiniteScrolling"></v-card>
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="primary"
        class="w-100 mt-10 mb-10"
      ></v-progress-circular>
      <div v-if="totalData === orderList.length" class="w-100 pa-5 d-flex justify-center">
        <span class="caption grey--text font-weight-bold">{{$_strings.statusOrder.END_OF_ORDER}}</span>
      </div>
    </section>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import { dateFormat, dateFilterFormat } from '../../../helper/commonHelpers';
import FilterOrder from '../FilterOrder.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  components: {
    FilterOrder,
  },
  created() {
    source = CancelToken.source();
    this.fetchData();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    const { fromDate, toDate, search } = this.$route.query;
    const currentDate = dayjs().add('7', 'days').format('YYYY-MM-DD');
    return {
      orderList: [],
      totalData: 0,
      isLoading: false,
      filter: {
        sort: 'pickupDate,DESC',
        pickupDateFrom: fromDate || dayjs(currentDate).subtract('7', 'days').format('YYYY-MM-DD'),
        pickupDateTo: toDate || currentDate,
        title: search || '',
        page: 0,
        size: 10,
      },
    };
  },
  watch: {
    '$route.path': function setQuery() {
      const { tab } = this.$route.params;
      const { query } = this.$route;
      const { pickupDateFrom, pickupDateTo, title } = this.filter;
      if (tab === 'needconfirmation' && !Object.keys(query).length) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            fromDate: pickupDateFrom,
            toDate: pickupDateTo,
            search: title,
          },
        });
      }
    },
  },
  methods: {
    dayjs,
    dateFormat,
    dateFilterFormat,
    async fetchData() {
      const status = 'NEED_CONFIRMATION';
      try {
        this.isLoading = true;
        const result = await this.$_services.order.getStatusOrder(this.filter, status, source);
        result.data.contents.forEach((data) => {
          this.orderList.push(data);
        });
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    setFilter(filterBy, value) {
      this.filter[filterBy] = value;
      this.orderList = [];
      this.filter.page = 0;
      this.fetchData();
    },
    viewDetail(orderId, title, latestStatus, serviceType) {
      const { pickupDateFrom, pickupDateTo } = this.filter;
      this.$router.push({
        name: 'order-status-detail',
        params: {
          id: orderId,
        },
        query: {
          status: 'NEED_CONFIRMATION',
          title,
          latestStatus,
          serviceType,
          pickupDateTo,
          pickupDateFrom,
        },
      });
    },
    getListInfiniteScroll() {
      if (!this.isLoading && this.orderList.length < this.totalData) {
        this.filter.page += 1;
        this.fetchData();
      }
    },
    infiniteScrolling(entries, observer, isIntersecting) {
      if (isIntersecting && !this.isLoading) {
        this.getListInfiniteScroll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-badge {
  span {
    color: black !important;
    font-weight: bold;
  }
}
v-card:hover {
  background: red;
  display: block;
  width: 200px;
  position: relative;
  height: 200px;
}
</style>
