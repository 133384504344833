<template>
  <v-sheet>
    <v-row
      dense
      align="center"
    >
      <v-col>
        <v-row dense>
          <v-col cols="auto">
            <v-menu
              ref="menuFromDate"
              v-model="menuFromDate"
              min-width="auto"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  class="caption"
                  placeholder="Mulai"
                  :value="fromDate ? dayjs(fromDate).format('DD-MM-YYYY') : ''"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                :max="toDate"
                @change="menuFromDate = false"
                no-title
                scrollable
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="auto" align-self="center" class="d-none d-sm-block">-</v-col>
          <v-col cols="auto">
            <v-menu
              ref="menuToDate"
              v-model="menuToDate"
              min-width="auto"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details
                  class="caption"
                  outlined
                  :value="toDate ? dayjs(toDate).format('DD-MM-YYYY') : ''"
                  placeholder="Akhir"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="fromDate"
                @change="menuToDate = false"
                v-model="toDate"
                no-title
                scrollable
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="ml-md-auto"
      >
        <v-text-field
          v-model="search"
          @keyup.native.enter="setSearch"
          dense
          hide-details
          outlined
          class="caption"
          :placeholder="$_strings.statusOrder.INPUT_ORDER_NO"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="setSearch">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'filter-order',
  data() {
    const { fromDate, toDate, search } = this.$route.query;
    const currentDate = dayjs().add('7', 'day').format('YYYY-MM-DD');
    return {
      search: search || '',
      menuFromDate: false,
      menuToDate: false,
      fromDate: fromDate || dayjs(currentDate).subtract('7', 'days').format('YYYY-MM-DD'),
      toDate: toDate || currentDate,
    };
  },
  watch: {
    fromDate() {
      this.$emit('setFilter', 'pickupDateFrom', this.fromDate);
      if (this.$route.query.pickupDateFrom !== this.fromDate) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            fromDate: this.fromDate,
          },
        });
      }
    },
    toDate() {
      this.$emit('setFilter', 'pickupDateTo', this.toDate);
      if (this.$route.query.pickupDateFrom !== this.toDate) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            toDate: this.toDate,
          },
        });
      }
    },
  },
  methods: {
    dayjs,
    setSearch() {
      if (this.$route.query.search !== this.search) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            search: this.search,
            fromDate: this.fromDate,
            toDate: this.toDate,
          },
        });
      }
      this.$emit('setFilter', 'title', this.search);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field--outlined.v-input--dense .v-input__control .v-text-field__slot > label.v-label {
  font-size: 10px !important;
  color: red !important;
}
</style>
