<template>
  <v-container fluid>
    <FilterOrder
      @setFilter="setFilter"
    />
    <v-sheet
      min-height="200"
      class="w-100 d-flex align-center justify-center"
      v-if="orderList.length === 0"
    >
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="primary"
        class="w-100 mt-10 mb-10"
      ></v-progress-circular>
      <h2 v-if="!isLoading">{{$_strings.common.NO_DATA}}</h2>
    </v-sheet>
    <section v-else class="my-4">
      <v-card
        :to="`/main/status-pesanan/detail/${item.id}?status=ON_PROCESS&title=${item.title}&latestStatus=${item.latestStatus}&serviceType=${item.serviceType}&pickupDateFrom=${dateFilterFormat(filter.pickupDateFrom)}&pickupDateTo=${dateFilterFormat(filter.pickupDateTo)}`"
        @click="viewDetail(item.id, item.title, item.latestStatus, item.serviceType)"
        v-for="(item, index) in orderList"
        v-bind:key="item.id"
        hover
        class="mb-5"
      >
        <v-card-title class="pb-0">
          {{item.title}} - {{item.latestStatus}}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="body-2 grey lighten-5">
          <div style="width: 20px;">
            <v-badge
              color="grey lighten-2"
              :content="index + 1"
            >
            </v-badge>
          </div>
          <v-divider v-for="divider in 1" vertical :key="divider" class="mr-2 ml-2"></v-divider>
          <v-icon>mdi-calendar</v-icon>
          <span class="mr-2">{{dateFormat(item.pickupDate)}}</span>
          <v-icon>mdi-clock-outline</v-icon>
          <span class="mr-2">{{timeFormat(item.pickupDate)}}</span>
          <v-icon>mdi-car</v-icon>
          <span class="mr-2">{{item.serviceType === 'FCL_BACKHAULING' ? 'FCL+BACKHAULING' : item.serviceType}}{{item.transportTypeName ? ` - ${item.transportTypeName}` : null}}</span>
          <v-icon>mdi-package-variant-closed</v-icon>
          <span class="mr-2">{{item.categoryName}} ({{item.subCategoryName}})</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                class="light-blue lighten-5"
                rounded
                small
                @click="(e) => e.preventDefault()"
              >
                <div>
                  <v-icon small color="blue" class="mr-2">mdi-clock-time-nine-outline</v-icon>
                  <span class="blue--text caption" v-if="item.etaDateTime">{{ $_strings.orderCreate.ETA }} : {{dateFormat(item.etaDateTime)}}</span>
                  <span v-else class="caption primary--text">{{ $_strings.orderCreate.ETA }} __/__/____</span>
                </div>
              </v-btn>
            </template>
            <v-list v-if="!item.etaDateTime">
              <v-list-item>
                <v-list-item-title>
                  <h5>{{ $_strings.orderCreate.ETA_NOT_FOUND }}</h5>
                  <span class="caption">karena data belum dikirimkan ke server</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col class="d-flex align-center" cols="auto">
              <v-icon color="light-blue lighten-2">
                mdi-map-marker
              </v-icon>
              <p class="mb-0 black--text pl-3">{{item.originLocationName}}</p>
            </v-col>
          </v-row>
          <v-row v-if="getShipmentDetail(item.shipmentsDetail, 'MULTIPICK').length > 0">
            <v-col cols="auto">
              <v-row>
                <v-col class="pt-0" cols="auto">
                  <v-icon color="green">
                    mdi-map-marker
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-col v-for="(multipick, indexMultipick) in getShipmentDetail(item.shipmentsDetail, 'MULTIPICK')" :key="indexMultipick" class="d-flex align-center pa-0" cols="10">
                  <p class="mb-0 black--text">Pick {{indexMultipick + 1}} - {{multipick.locationName}}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mx-4 mt-4"></v-divider>
          <v-row class="pl-5 mt-2" v-if="getShipmentDetail(item.shipmentsDetail, 'MULTIDROP').length > 0">
            <v-col cols="auto">
              <v-row>
                <v-col class="pt-0" cols="auto">
                  <v-icon color="red accent-4">
                    mdi-map-marker
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-col v-for="(multidrop, indexMultidrop) in getShipmentDetail(item.shipmentsDetail, 'MULTIDROP')" :key="indexMultidrop" class="d-flex align-center pa-0" cols="10">
                  <p :class="getShipmentDetail(item.shipmentsDetail, 'MULTIDROP').length - 1 === indexMultidrop ? 'mb-2': 'mb-0' " class="black--text">Drop {{indexMultidrop + 1}} - {{multidrop.locationName}}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div v-else class="mt-2"></div>
          <v-row class="pl-5 mt-1">
            <v-col class="d-flex align-center pt-0" cols="auto">
              <v-icon color="yellow accent-4">
                mdi-map-marker
              </v-icon>
              <p class="mb-0 black--text pl-3">{{item.destinationLocationName}}</p>
            </v-col>
          </v-row>
          <Backhauling
            v-if="item.backhauling"
            :item="item.backhauling"
          />
        </v-card-text>
      </v-card>
      <v-card v-intersect="infiniteScrolling"></v-card>
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="primary"
        class="w-100 mt-10 mb-10"
      ></v-progress-circular>
      <div v-if="totalData === orderList.length" class="w-100 pa-5 d-flex justify-center">
        <span class="caption grey--text font-weight-bold">{{$_strings.statusOrder.END_OF_ORDER}}</span>
      </div>
    </section>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import { dateFormat, timeFormat, dateFilterFormat } from '../../../helper/commonHelpers';
import FilterOrder from '../FilterOrder.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  components: {
    FilterOrder,
  },
  created() {
    source = CancelToken.source();
    this.fetchData();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    const { fromDate, toDate, search } = this.$route.query;
    const currentDate = dayjs().add('7', 'days').format('YYYY-MM-DD');
    return {
      orderList: [],
      totalData: 0,
      isLoading: false,
      filter: {
        sort: 'pickupDate,DESC',
        pickupDateFrom: fromDate || dayjs(currentDate).subtract('7', 'days').format('YYYY-MM-DD'),
        pickupDateTo: toDate || currentDate,
        title: search || '',
        page: 0,
        size: 10,
      },
    };
  },
  watch: {
    '$route.path': function setQuery() {
      const { tab } = this.$route.params;
      const { query } = this.$route;
      const { pickupDateFrom, pickupDateTo, title } = this.filter;
      if (tab === 'process' && !Object.keys(query).length) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            fromDate: pickupDateFrom,
            toDate: pickupDateTo,
            search: title,
          },
        });
      }
    },
  },
  methods: {
    dayjs,
    dateFilterFormat,
    dateFormat,
    timeFormat,
    async fetchData() {
      const status = 'ON_PROCESS';
      try {
        this.isLoading = true;
        const result = await this.$_services.order.getStatusOrder(this.filter, status, source);
        result.data.contents.forEach((data) => {
          this.orderList.push(data);
        });
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    setFilter(filterBy, value) {
      this.filter[filterBy] = value;
      this.orderList = [];
      this.filter.page = 0;
      this.fetchData();
    },
    viewDetail(orderId, title, latestStatus, serviceType) {
      const { pickupDateFrom, pickupDateTo } = this.filter;
      this.$router.push({
        name: 'order-status-detail',
        params: {
          id: orderId,
        },
        query: {
          status: 'ON_PROCESS',
          title,
          latestStatus,
          serviceType,
          pickupDateFrom,
          pickupDateTo,
        },
      });
    },
    getShipmentDetail(shipmentDetail, multiType) {
      const data = [];
      if (shipmentDetail) {
        shipmentDetail.forEach((e) => {
          if (e.multiType === multiType) {
            data.push(e);
          }
        });
      }
      return data;
    },
    getListInfiniteScroll() {
      if (!this.isLoading && this.orderList.length < this.totalData) {
        this.filter.page += 1;
        this.fetchData();
      }
    },
    infiniteScrolling(entries, observer, isIntersecting) {
      if (isIntersecting && !this.isLoading) {
        this.getListInfiniteScroll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-badge {
  span {
    color: black !important;
    font-weight: bold;
  }
}
v-card:hover {
  background: red;
  display: block;
  width: 200px;
  position: relative;
  height: 200px;
}
</style>
